
  import {
    ref, Ref, defineComponent, onMounted, onUnmounted, computed, ComponentPublicInstance,
  } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import { KeyCode } from '@/config/keyboards/dataTypes';
  import { codeProxy } from '@/utils';
  import { shortcutKeys, shortcutActions } from '@/components/key-shortcut/shortcuts';

  import Button from '@/components/Button.vue';
  import Surface from '@/components/html-elements/Surface.vue';

  export default defineComponent({
    name: 'Home',

    components: {
      Button,
      Surface,
    },

    setup() {
      const {
        t, locale, d,
      } = useI18n({ useScope: 'global' });
      const router = useRouter();
      const store = useStore();

      const lastLesson = store.getters['lesson/last'];
      const todayStat = store.getters['lesson/todayStat'];
      const layoutProps = store.getters['layout/props'];

      if (!lastLesson || !todayStat || !layoutProps) {
        window.location.replace('/');
      }

      // Metrics

      type Metric = {
        value: string,
        units: string
      }

      // Speed metric

      const getLocalSpeed = (speedInWpm: number): Metric => {
        const speed: Metric = {
          value: String(Math.round(speedInWpm)),
          units: locale.value === 'en-US' ? 'wpm' : 'cpm',
        };

        if (speed.units === 'wpm') {
          speed.value = String(Math.round(speedInWpm / 5));
        }

        return speed;
      };

      // Lesson metrics

      const lessonMetrics = computed(() => {
        // Accuracy
        const accuracy = {
          value: String(Math.round(lastLesson.accuracy)),
          units: '%',
        };

        // Duration
        const durationeInSeconds = lastLesson.duration.asSeconds();
        const duration: Metric = {
          value: String(Math.round(durationeInSeconds)),
          units: 'sec',
        };
        if (durationeInSeconds >= 60) {
          duration.value = `${lastLesson.duration.minutes()}:${String(lastLesson.duration.seconds()).padStart(2, '0')}`;
          duration.units = 'min';
        }

        return {
          speed: getLocalSpeed(lastLesson.speed),
          accuracy,
          duration,
        };
      });

      // Day statistics

      const dayMoment = todayStat.todayMoment.toDate();

      const dayStat = computed(() => {
        const timeAsSeconds = todayStat.overallTime.asSeconds();
        const time = {
          value: Math.round(timeAsSeconds),
          units: 'seconds',
        };

        if (timeAsSeconds >= 60) {
          time.value = Math.round(todayStat.overallTime.asMinutes());
          time.units = 'minutes';
        }

        return {
          lessons: todayStat.lessonsCount,
          time,
          speed: getLocalSpeed(todayStat.avgSpeed),
          accuracy: Math.round(todayStat.avgAccuracy),
        };
      });

      // Keybinds

      const keybinds = {
        continue: {
          key: shortcutKeys.space,
          action: shortcutActions.continue,
        },
        restart: {
          key: shortcutKeys.esc,
          action: shortcutActions.restart,
        },
      };

      const repeatLesson: Ref<null | ComponentPublicInstance> = ref(null);
      const nextLesson: Ref<null | ComponentPublicInstance> = ref(null);

      const handleKeybind = (e: KeyboardEvent) => {
        const code = codeProxy(e.code as KeyCode<null>, window.navigator, layoutProps.format);

        if (code === keybinds.restart.key.code) {
          e.preventDefault();

          router.push({ name: 'trainer', query: { repeat: 'true' } });

          // if (repeatLesson.value) {
          //   repeatLesson.value.$emit('click');
          // } else {
          //   // TODO process exception - when there is not repeatLesson element on page
          // }
        } else if (code === keybinds.continue.key.code) {
          e.preventDefault();

          router.push({ name: 'trainer' });

          // if (nextLesson.value) {
          //   nextLesson.value.$emit('click');
          // } else {
          //   // TODO process exception - when there is not nextLesson element on page
          // }
        }
      };

      onMounted(() => {
        document.addEventListener('keydown', handleKeybind);
      });

      onUnmounted(() => {
        document.removeEventListener('keydown', handleKeybind);
      });

      return {
        t,
        d,
        router,
        lessonMetrics,
        dayMoment,
        dayStat,
        shortcutActions,
        repeatLesson,
        nextLesson,
      };
    },
  });
