<template>
  <section class="result">
    <Surface class="lesson-metrics-wrapper">
      <div class="lesson-metric">
        <img
          :src="require(`@/assets/img/icons/fire.png`)"
          class="icon"
          alt="Fire"
        >
        <div class="value">
          <div class="number">
            {{ lessonMetrics.speed.value }}
          </div>
          <div class="units">
            {{ t(`speedUnits.${lessonMetrics.speed.units}`) }}
          </div>
        </div>
      </div>
      <div class="lesson-metric">
        <img
          :src="require(`@/assets/img/icons/target.png`)"
          class="icon"
          alt="Target"
        >
        <div class="value">
          <div class="number">
            {{ lessonMetrics.accuracy.value }}
          </div>
          <div class="units">
            {{ lessonMetrics.accuracy.units }}
          </div>
        </div>
      </div>
      <div class="lesson-metric">
        <img
          :src="require(`@/assets/img/icons/hourglass.png`)"
          class="icon"
          alt="Hourglass"
        >
        <div class="value">
          <div class="number">
            {{ lessonMetrics.duration.value }}
          </div>
          <div class="units">
            {{ t(`durationUnits.${lessonMetrics.duration.units}`) }}
          </div>
        </div>
      </div>
    </Surface>

    <div class="day-metrics-wrapper">
      <div class="info">
        <div class="date">
          {{ d(dayMoment, 'short') }}
        </div>
      </div>
      <div class="metrics">
        <div class="metric">
          {{ t('pages.result.todayStat.lessons', dayStat.lessons) }}
        </div>
        <div class="metric">
          {{ t(`pages.result.todayStat.${dayStat.time.units}`, dayStat.time.value) }}
        </div>
        <div class="metric">
          {{ dayStat.speed.value }} {{ t(`speedUnits.${dayStat.speed.units}`) }}
        </div>
        <div class="metric">
          {{ t('pages.result.todayStat.accuracy', {val: dayStat.accuracy + '%'}) }}
        </div>
      </div>
    </div>

    <div class="actions-wrapper">
      <Button
        type="secondary"
        class="go-home"
        @click="router.push({name: 'profile'})"
      >
        {{ t(`pages.result.actionButtons.home`) }}
      </Button>

      <Button
        ref="repeatLesson"
        type="secondary"
        keybind="Esc"
        class="restart"
        @click="router.push({ name: 'trainer', query: {repeat: 'true'}})"
      >
        {{ t(`pages.result.actionButtons.repeat`) }}
      </Button>

      <Button
        ref="nextLesson"
        type="primary"
        keybind="Space"
        class="continue"
        @click="router.push({ name: 'trainer'})"
      >
        {{ t(`pages.result.actionButtons.continue`) }}
      </Button>
    </div>
  </section>
</template>

<script lang="ts">
  import {
    ref, Ref, defineComponent, onMounted, onUnmounted, computed, ComponentPublicInstance,
  } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import { KeyCode } from '@/config/keyboards/dataTypes';
  import { codeProxy } from '@/utils';
  import { shortcutKeys, shortcutActions } from '@/components/key-shortcut/shortcuts';

  import Button from '@/components/Button.vue';
  import Surface from '@/components/html-elements/Surface.vue';

  export default defineComponent({
    name: 'Home',

    components: {
      Button,
      Surface,
    },

    setup() {
      const {
        t, locale, d,
      } = useI18n({ useScope: 'global' });
      const router = useRouter();
      const store = useStore();

      const lastLesson = store.getters['lesson/last'];
      const todayStat = store.getters['lesson/todayStat'];
      const layoutProps = store.getters['layout/props'];

      if (!lastLesson || !todayStat || !layoutProps) {
        window.location.replace('/');
      }

      // Metrics

      type Metric = {
        value: string,
        units: string
      }

      // Speed metric

      const getLocalSpeed = (speedInWpm: number): Metric => {
        const speed: Metric = {
          value: String(Math.round(speedInWpm)),
          units: locale.value === 'en-US' ? 'wpm' : 'cpm',
        };

        if (speed.units === 'wpm') {
          speed.value = String(Math.round(speedInWpm / 5));
        }

        return speed;
      };

      // Lesson metrics

      const lessonMetrics = computed(() => {
        // Accuracy
        const accuracy = {
          value: String(Math.round(lastLesson.accuracy)),
          units: '%',
        };

        // Duration
        const durationeInSeconds = lastLesson.duration.asSeconds();
        const duration: Metric = {
          value: String(Math.round(durationeInSeconds)),
          units: 'sec',
        };
        if (durationeInSeconds >= 60) {
          duration.value = `${lastLesson.duration.minutes()}:${String(lastLesson.duration.seconds()).padStart(2, '0')}`;
          duration.units = 'min';
        }

        return {
          speed: getLocalSpeed(lastLesson.speed),
          accuracy,
          duration,
        };
      });

      // Day statistics

      const dayMoment = todayStat.todayMoment.toDate();

      const dayStat = computed(() => {
        const timeAsSeconds = todayStat.overallTime.asSeconds();
        const time = {
          value: Math.round(timeAsSeconds),
          units: 'seconds',
        };

        if (timeAsSeconds >= 60) {
          time.value = Math.round(todayStat.overallTime.asMinutes());
          time.units = 'minutes';
        }

        return {
          lessons: todayStat.lessonsCount,
          time,
          speed: getLocalSpeed(todayStat.avgSpeed),
          accuracy: Math.round(todayStat.avgAccuracy),
        };
      });

      // Keybinds

      const keybinds = {
        continue: {
          key: shortcutKeys.space,
          action: shortcutActions.continue,
        },
        restart: {
          key: shortcutKeys.esc,
          action: shortcutActions.restart,
        },
      };

      const repeatLesson: Ref<null | ComponentPublicInstance> = ref(null);
      const nextLesson: Ref<null | ComponentPublicInstance> = ref(null);

      const handleKeybind = (e: KeyboardEvent) => {
        const code = codeProxy(e.code as KeyCode<null>, window.navigator, layoutProps.format);

        if (code === keybinds.restart.key.code) {
          e.preventDefault();

          router.push({ name: 'trainer', query: { repeat: 'true' } });

          // if (repeatLesson.value) {
          //   repeatLesson.value.$emit('click');
          // } else {
          //   // TODO process exception - when there is not repeatLesson element on page
          // }
        } else if (code === keybinds.continue.key.code) {
          e.preventDefault();

          router.push({ name: 'trainer' });

          // if (nextLesson.value) {
          //   nextLesson.value.$emit('click');
          // } else {
          //   // TODO process exception - when there is not nextLesson element on page
          // }
        }
      };

      onMounted(() => {
        document.addEventListener('keydown', handleKeybind);
      });

      onUnmounted(() => {
        document.removeEventListener('keydown', handleKeybind);
      });

      return {
        t,
        d,
        router,
        lessonMetrics,
        dayMoment,
        dayStat,
        shortcutActions,
        repeatLesson,
        nextLesson,
      };
    },
  });
</script>

<style lang="scss" scoped>
.result {
  display: grid;
  grid:
    "." 3rem
    "lesson-metrics" min-content
    "." auto
    "day-metrics" min-content
    "." 1.5rem
    "actions" min-content
    "." 1.5rem
    / 100%;

  .lesson-metrics-wrapper {
    grid-area: lesson-metrics;
    display: flex;

    .lesson-metric {
      flex: 1;
      padding: 1.5rem 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &:not(:first-child) {
        border-left: 1px solid rgb(var(--color-secondary-border));
      }

      .icon {
        height: 3rem;
      }

      .value {
        display: flex;
        align-items: baseline;

        .number {
          font-size: 3.5rem;
          line-height: 1;
          font-weight: 600;
          margin: 0 .1rem 0 1rem;
        }

        .units {
          color: rgb(var(--color-text-secondary));
          font-weight: 400;
          font-size: 1.25rem;
        }
      }
    }
  }

  .day-metrics-wrapper {
    grid-area: day-metrics;
    display: flex;
    border-top: 1px solid rgb(var(--color-secondary-border));
    border-bottom: 1px solid rgb(var(--color-secondary-border));
    padding: 1.25rem 0;

    .info {
      flex: 0 0 auto;

      .date {
        font-weight: 500;
        font-size: 1.125rem;
      }

      .subtitle {
        color: rgb(var(--color-text-secondary));
      }
    }

    .metrics {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .metric {
        font-size: .875rem;
        text-transform: uppercase;
        position: relative;
        margin-left: 2.5rem;

        &:before {
          $size: .375rem;
          content: " ";
          display: block;
          position: absolute;
          top: 0;
          left: -#{$size * 2};
          bottom: 0;
          margin: auto;
          width: $size;
          height: $size;
          border-radius: $size;
          background: rgb(var(--color-primary));
        }
      }
    }
  }

  .actions-wrapper {
    grid-area: actions;
    display: grid;
    grid: "go-home . restart . continue" auto / max-content auto max-content 10px max-content;

    .go-home {
      grid-area: go-home;
    }

    .restart {
      grid-area: restart;
    }

    .continue {
      grid-area: continue;
    }
  }
}
</style>
